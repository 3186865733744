body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef2fb;
}

code {
  font-family: "Montserrat";
}



footer {
  position: fixed;
  height: auto;
  max-height: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eef2fb;
  color: #9f9f9f;
}

a {
  color: #9f9f9f;
}

p{
  text-align: center;
  line-height: 0;
}